import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";

import ImageStage from "src/common/brands/ImageStage";
import ContentWidthWrapper from "../ContentWidthWrapper";

interface Props {
  title: string | undefined | null;
  subtitle: string | undefined | null;
  logoDepot?: string | undefined | null;
  logoIpuro?: string | undefined | null;
  logoRooms?: string | undefined | null;
}

const BrandsStage = ({
  title,
  subtitle,
  logoDepot,
  logoIpuro,
  logoRooms,
}: Props) => {
  return (
    <Background>
      <ContentWidthWrapper padding>
        <Wrapper container>
          <TextWrapper item xs={12} sm={8} md={5}>
            <Headline variant="h1">{title}</Headline>
            <Subheadline variant="subtitle1">{subtitle}</Subheadline>
            <ImagesWrapper>
              {logoDepot && <ImageStage imageUrl={logoDepot} />}
              {logoIpuro && <ImageStage imageUrl={logoIpuro} />}
              {logoRooms && <ImageStage imageUrl={logoRooms} />}
            </ImagesWrapper>
          </TextWrapper>
        </Wrapper>
      </ContentWidthWrapper>
    </Background>
  );
};

export default BrandsStage;

const Background = styled.div(
  ({ theme }) => `
  background-color: ${theme.palette.dust.main};
  margin-bottom: ${theme.spacing(10)}px;
  ${theme.breakpoints.up("md")} {
margin-bottom: ${theme.spacing(25)}px;
}
`
);

const Wrapper = styled(Grid)(
  ({ theme }) => `
    display: flex;
    justify-content: flex-start;
    max-width: 1280px;
    margin: 0 auto;
    padding: ${theme.spacing(8, 0, 0)};
    ${theme.breakpoints.up("sm")} {
        flex-direction: row;
        padding: ${theme.spacing(15, 0, 0)};
    }
`
);

const Subheadline = styled(Typography)(
  ({ theme }) => `
  ${theme.breakpoints.up("sm")} {
    width: 80%;
}

`
);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: ${theme.spacing(3)}px;
    padding: ${theme.spacing(0, 0, 2)};
    position: relative;
    ${theme.breakpoints.up("sm")} {
      margin-top: ${theme.spacing(0)};

  }
    ${theme.breakpoints.up("md")} {
        margin-top: 0;
        padding: ${theme.spacing(14, 0, 0)};
    }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)}px;
  ${theme.breakpoints.up("sm")} {
    margin-bottom: ${theme.spacing(3)}px;
  }
`
);

const ImagesWrapper = styled.div(
  ({ theme }) => `
display: flex;
flex-direction: row;
position: absolute;
bottom:-85px;
z-index: 20;
${theme.breakpoints.up("md")} {
  bottom: -180px;
  right: -120%;
}

`
);
