import React from "react";
import { Hidden } from "@material-ui/core";
import ContentfulImage from "../components/ContentfulImage";
import styled from "styled-components";

interface Props {
  imageUrl: string;
}

const ImageStage = ({ imageUrl }: Props) => {
  return (
    <Image>
      <Hidden smDown>
        <ContentfulImage url={imageUrl} width={175} height={175} />
      </Hidden>
      <Hidden mdUp>
        <ContentfulImage url={imageUrl} width={85} height={85} />
      </Hidden>
    </Image>
  );
};

export default ImageStage;

const Image = styled.div(
  ({ theme }) => `
  margin-right: ${theme.spacing(1)}px;
  ${theme.breakpoints.up("sm")} {
    margin-right: ${theme.spacing(3)}px;
}
`
);
