import React from "react";
import Layout from "src/common/Layout";
import { graphql } from "gatsby";
import { BrandsPageQuery } from "types/graphql-types";
import "react-json-pretty/themes/1337.css";
import BrandsStage from "src/common/components/Stages/BrandsStage";
import { Grid, Typography, Hidden } from "@material-ui/core";
import ContentfulRichText from "src/common/components/ContentfulRichText";
import ContentfulImage from "src/common/components/ContentfulImage";
import styled from "styled-components";
import SEO from "src/common/components/seo";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";

const brands = ({
  data: { contentfulBrandPage, contentfulFooter, contentfulHeader },
}: {
  data: BrandsPageQuery;
}) => {
  if (!contentfulBrandPage || !contentfulFooter || !contentfulHeader) {
    throw new Error("Not all Contentful requirements met: contentfulBrandPage, contentfulFooter, contentfulHeader");
  }
  const { pageConfig } = contentfulBrandPage;
  const brandList = contentfulBrandPage?.brandList!;

  return (
    <Layout
      contentfulFooter={contentfulFooter}
      contentfulHeader={contentfulHeader}
      navigationIsDark
      stageContent={
        <BrandsStage
          title={pageConfig!.title!.title!}
          subtitle={pageConfig!.subtitle!.subtitle!}
          logoDepot={brandList[0]!.logo?.file?.url}
          logoIpuro={brandList[1]!.logo?.file?.url}
          logoRooms={brandList[2]!.logo?.file?.url}
        />
      }
    >
      <SEO
        title={pageConfig!.seoConfig?.title!}
        description={pageConfig!.seoConfig?.description}
        image={pageConfig!.seoConfig?.image?.localFile?.publicURL!}
      />
      <ContentWidthWrapper padding>
        {brandList?.map((brand: any) => {
          return (
            <Container id={brand.linkIdentifier} key={brand.id}>
              <Grid container justify="center">
                <Hidden only={["xs", "sm"]}>
                  <Grid item md={5}>
                    {brand.devices && (
                      <div style={{ marginBottom: 32 }}>
                        <ContentfulImage
                          url={brand.devices.file.url}
                          width={"100%"}
                          height={"auto"}
                        />
                      </div>
                    )}
                    <ContentfulImage
                      url={brand.image.file.url}
                      width={"100%"}
                      height={"auto"}
                    />
                  </Grid>
                </Hidden>
                <Grid item md={7} xs={12}>
                  <Description>
                    <Typography variant="h1" component="h2" gutterBottom>
                      {brand.title}
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                      {brand.slogan}
                    </Typography>
                    <ContentfulRichText
                      json={brand.richtext.json}
                      showInitial
                    />
                  </Description>
                </Grid>
                {brand.imageMobile && (
                  <Hidden mdUp>
                    <Grid item xs={12} sm={8}>
                      <ContentfulImage
                        url={brand.imageMobile?.file.url}
                        width={"100%"}
                        height={"auto"}
                      />
                    </Grid>
                  </Hidden>
                )}
              </Grid>
            </Container>
          );
        })}
      </ContentWidthWrapper>
    </Layout>
  );
};

const Container = styled("div")(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(5)}px;
  ${theme.breakpoints.up("sm")} {
    margin-bottom: ${theme.spacing(13)}px;
  }
`
);

const Description = styled("div")(
  ({ theme }) => `
  padding-left: ${theme.spacing(0)}px;
  margin-bottom: ${theme.spacing(5)}px;
  ${theme.breakpoints.up("md")} {
    margin-bottomÖ ${theme.spacing(0)}px;
    padding-left: ${theme.spacing(5)}px;
  }
`
);

export const query = graphql`
  query BrandsPage ($locale:String!){
    contentfulBrandPage (node_locale: {eq: $locale}) {
      pageConfig {
        title {
          title
        }
        subtitle {
          subtitle
        }
        headerImage {
          file {
            url
          }
          localFile {
            publicURL
          }
        }
        seoConfig {
          title
          description
          image {
            file {
              url
            }
            localFile {
              publicURL
            }
          }
        }
      }
      brandList {
        id
        title
        slogan
        linkIdentifier
        logo {
          file {
            url
          }
        }
        devices {
          file {
            url
          }
        }
        imageMobile {
          file {
            url
          }
        }
        image {
          file {
            url
          }
        }
        richtext {
          json
        }
      }
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;

export default brands;
